// Contact
.contact {
  position: left;
  top: -10%;
}

.contact-logo {
  height: 15vmin;
  pointer-events: none;
}

